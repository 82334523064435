<template>
  <div class="app-container">
    <div class="content_data">
      <div
        style="display: flex; align-items: center;width:98%;margin: 0 auto;height:70px"
      >
        <div style="width:40px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">总客户数</div>
            <span>{{ orgSum }}</span>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">正常客户</div>
            <span>{{ normalCount }}</span>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jiezhang"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">本月新增</div>
            <span>{{ monthCount }}</span>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan1"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">签约账套数</div>
            <span>{{ signNumber }}</span>
          </div>
        </div>
        <div class="each" style="width: 135px;">
          <div class="icon_bg">
            <i class="iconfont icon-jizhanghesuan"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">系统已使用账套数</div>
            <span>{{ usedNumber }}</span>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-lishishujucaiji"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">正常使用数</div>
            <span>{{ normalSignNumber }}</span>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zhongjihuiji"></i>
          </div>
          <div style="margin-left:5px">
            <div style="margin-bottom:5px">同比上期增长</div>
            <span>{{ yearGrowth }}%</span>
          </div>
        </div>
        <div class="data_time">
          月份筛选：
          <el-date-picker
            v-model="listQuery1.startDate"
            type="month"
            placeholder="请选择时间"
            value-format="YYYY-MM"
            size="small"
            style="width:120px"
            @change="getInfo"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="content_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          @click="getList()"
        ></el-button>
        <el-input
          placeholder="请输入公司名称/报税手机号"
          v-model="listQuery.name"
          style="width: 200px;margin: 0 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <span>
          到期时间：
          <el-date-picker
            v-model="listQuery.startDqPeriod"
            type="date"
            value-format="YYYY-MM-DD"
            style="width: 120px;margin: 0 5px;font-size: 12px;"
            placeholder="选择日期">
          </el-date-picker>-
          <el-date-picker
            v-model="listQuery.endPeriod"
            type="date"
            value-format="YYYY-MM-DD"
            style="width: 120px;margin: 0 5px;font-size: 12px;"
            placeholder="选择日期">
          </el-date-picker>
        </span>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px;"
          icon="Search"
          >搜索</el-button
        >
        <qzf-search-period
          v-model:period="listQuery.startPeriod"
          :clearable="false"
          style="width:100px"
          @success="getList"
        ></qzf-search-period>
        <span style="margin-left: 5px;"
          ><el-switch
            v-model="listQuery.date"
            active-text="两月内到期"
            inactive-text=""
            @change="getList"
            size="small"
          ></el-switch
        ></span>
        <!-- <span style="margin-left: 5px;"
          ><el-switch
            v-model="listQuery.month"
            active-text="签约时间为一个月"
            inactive-text=""
            @change="getList"
            size="small"
          ></el-switch
        ></span> -->
        <span style="margin-left: 5px;"
          ><el-switch
            v-model="listQuery.number"
            active-text="剩余10账套"
            inactive-text=""
            @change="getList"
            size="small"
          ></el-switch
        ></span>
      </div>

      <div>
        <el-button
          type="primary"
          plain
          size="small"
          icon="Refresh"
          @click="freshList"
          >刷新列表</el-button
        >
        <el-button
          type="primary"
          plain
          size="small"
          icon="Edit"
          @click="batchEdit"
          >修改销售经理</el-button
        >
        <el-button
          type="success"
          plain
          size="small"
          icon="FolderOpened"
          @click="dayin"
          >打印</el-button
        >
        <el-button
          type="primary"
          plain
          size="small"
          icon="Folder"
          @click="daochu"
          >导出</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        stripe
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column type="index" label="序号" width="55" align="center" />
        <el-table-column prop="name" label="签约时间" min-width="120" sortable>
          <template #default="scope">
            <span>{{ $parseTime(scope.row.countTime, "{y}-{m}-{d}") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地区" min-width="120">
        </el-table-column>
        <el-table-column
          prop="name"
          label="公司名称"
          min-width="280"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div style="display: flex;align-items: center;">
              <div
                style="width: 90%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              >
                <span>{{ scope.row.name }}</span>
              </div>
              <el-tooltip content="复制" placement="top" effect="dark">
                <i
                  class="iconfont icon-fuzhi1"
                  style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                  @click="$copyComName(scope.row.name)"
                >
                </i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="count"
          label="签约账套数"
          min-width="120"
          sortable
        >
          <template #default="scope">
            <span>{{ Number(scope.row.count) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="usedNumber"
          label="系统已使用账套数"
          min-width="120"
          sortable
        >
        </el-table-column> -->
        <el-table-column
          prop="normalSignNumber"
          label="正常使用数"
          min-width="120"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="monthCount"
          label="本月使用数"
          min-width="120"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="yearGrowth"
          label="同比上期增长"
          min-width="120"
          sortable
        >
          <template #default="scope"> {{ scope.row.yearGrowth }}% </template>
        </el-table-column>
        <el-table-column
          prop="declarationNumber"
          label="本月申报数"
          min-width="120"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="countUseTime"
          label="到期时间"
          min-width="120"
          sortable
        >
        </el-table-column>
        <el-table-column prop="saleName" label="销售经理" min-width="100">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="修改销售经理"
    width="600px"
    destroy-on-close
  >
    <el-form>
      <el-form-item label="销售经理：">
        <el-input v-model="saleName" style="width: 200px;" size="small" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="save" size="small">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  statisticsCustomerList,
  statisticsList,
  updateSaleName,
  refreshStatisticsCache
} from "@/api/maintain";
import {wbVoucherFxOther} from '@/api/export'
import { currentAccountPeriod } from "@/utils";
export default {
  name: "useSituation",
  components: {},
  props: {},
  data() {
    return {
      shortcuts: [
        {
          text: "最近一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        startPeriod: currentAccountPeriod(),
        number:false,
        date:false,
        month:false,
        startDqPeriod: "",
        endDate: "",
      },
      listQuery1: {
        startDate: "",
        endDate: "",
      },
      loading: false,
      tableData: [],
      total: 0,
      dateRange: [],
      monthCount: 0,
      normalCount: 0,
      normalSignNumber: 0,
      orgSum: 0,
      signNumber: 0,
      yearGrowth: 0,
      usedNumber: 0,
      sels: [],
      dialogFormVisible: false,
      saleName: "",
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(280);
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      statisticsCustomerList(this.listQuery1).then((res) => {
        if (res.data.msg == "success") {
          this.monthCount = res.data.data.monthCount;
          this.normalCount = res.data.data.normalCount;
          this.normalSignNumber = res.data.data.normalSignNumber;
          this.orgSum = res.data.data.orgSum;
          this.signNumber = res.data.data.signNumber;
          this.yearGrowth = res.data.data.yearGrowth;
          this.usedNumber = res.data.data.usedNumber;
        }
      });
    },
    getList() {
      this.loading = true;
      statisticsList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    freshList(){
      this.loading = true;
      refreshStatisticsCache({period:this.listQuery.startPeriod}).then((res) => {
        if (res.data.msg == "success") {
          this.getList()
        }else{
          this.loading = false;
        }
      });
    },
    handleSelectionChange(e) {
      this.sels = e;
    },
    batchEdit() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.dialogFormVisible = true;
    },
    save() {
      if (!this.saleName) {
        this.$qzfMessage("请输入姓名", 1);
        return;
      }
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      let param = {
        ids,
        saleName: this.saleName,
      };
      updateSaleName(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.dialogFormVisible = false;
          this.saleName = "";
          this.getList();
        }
      });
    },
    daochu(){
      let ids = []
      this.sels.map(v=>{
        ids.push(v.Id)
      })
      let param = {
        outType:"excel",
        exportType:"statistics",
        query:{
          startPeriod:this.listQuery.startPeriod,
          ids:ids
        }
      }
      wbVoucherFxOther(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    dayin(){
      let ids = []
      this.sels.map(v=>{
        ids.push(v.Id)
      })
      let param = {
        outType:"pdf",
        exportType:"statistics",
        query:{
          startPeriod:this.listQuery.startPeriod,
          ids:ids
        }
      }
      wbVoucherFxOther(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.content_data {
  background-color: #d8ecff;
  margin-bottom: 10px;
  font-size: 12px;
  position: relative;
  .iconfont {
    font-size: 18px;
    color: #17a2b8;
  }
}
.each {
  width: 120px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
}
.each span {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon_bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 30px;
}
.data_time {
  position: absolute;
  right: 10px;
  top: 10px;
}
.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-input){
  height: 24px;
  line-height: 24px;
}
</style>
